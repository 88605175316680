<template>
  <div
    class="tab-pane fade"
    id="product-marketplace"
    role="tabpanel"
    aria-labelledby="product-marketplace-tab"
  >
    <!-- Marketplace -->
    <Content
      v-bind:marketplaces="marketplaceData"
      v-bind:product-detail="product.info"
      v-bind:categories="product.info.categories"
      v-model="product.detail.marketplaces"
      ref="content"
    />
    <!-- ./Marketplace -->
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
import Content from "./views/Content/Index";
export default {
  name: "Marketplace",
  data() {
    return {
      marketplaceData: [],
    };
  },
  components: {
    Content,
  },
  methods: {
    ...mapActions("marketplace", ["getList"]),
    setMarketplaceList() {
      const marketList = this.marketplace.list;
      const productMarketplace = this.product.detail.marketplaces; // Data From Store -> Api
      // Önce sistemdeki pazaryerlerini çekiyoruz
      if (marketList && marketList.length) {
        marketList.forEach((marketplace) => {
          // Eğer pazaryeri bilgileri daha önceden marketplaceData dizisinde mevcutsa
          let localMarket = this.marketplaceData.find(
            (local) => local.market.id == marketplace.id
          );

          const data = {};
          data.market = helper.clone(marketplace); // Pazaryeri bilgilerini kopyalıyoruz (id, logo, title, name)
          data.categories = localMarket ? localMarket.categories : [];

          // localMarket mevcut değilse (Yani daha önceden pazaryeri bilgileri doldurulmamışsa)
          if (!localMarket) {
            data.selectedCategory = null;
            data.category = {
              id: null,
              category_name: "Yeni Kategori Eşleştir",
            };

            data.listing_percent_type = "equal"; // Liste Fiyatı
            data.listing_price_type = "price"; // Liste Fiyatı
            data.listing_percent = null;

            data.sale_percent_type = "equal"; // Satış Fiyatı
            data.sale_price_type = "price"; // Satış Fiyatı
            data.sale_percent = null;

            data.extra_cost = 0; // İlave Maliyet
            data.extra_cost_unmask = 0; // İlave Maliyet

            data.fixed_price = false; // Sabit Fiyat
            data.fixed_quantity = false; // Sabit Stok
            data.category_attributes = [];
          }

          switch (marketplace.name) {
            case "trendyol":
            case "pazarama":
              data.brand_options = localMarket ? localMarket.brand_options : [];
              break;
            case "vodafone":
              data.brand_options = localMarket ? localMarket.brand_options : [];
              data.cargoCompany_options = localMarket
                ? localMarket.cargoCompany_options
                : [];
              break;

            case "n11":
              data.shipment_template = null;
              data.shipment_templates = localMarket
                ? localMarket.shipment_templates
                : [];
            case "hepsiburada":
              data.sku_no = localMarket ? localMarket.sku_no : null;
              break;
          }

          // categories API isteğinden gelen kategorilerden,
          // döngüdeki pazaryeri ile eşleşeni filtreliyoruz
          const categoryMarketplace = this.categoryWithMarketplaces.find(
            (category) => {
              return category.marketplaces.filter(
                (cm) => cm.marketplace_id === marketplace.id
              ).length;
            }
          );

          if (categoryMarketplace) {
            // Eşleşen kategoriyi categories->marketplaces kısmından kontrol ediyoruz - return object
            const matchedCategory = categoryMarketplace.marketplaces.find(
              (cm) => cm.marketplace_id === marketplace.id
            );

            if (matchedCategory) {
              // Eşleşen kategorinin id'sini category_id keyine atıyoruz
              data.category_id = categoryMarketplace.id;
            }
          }

          // Data from API -> Product Detail
          // API'den gelen datayı store'da tutuyoruz. Döngüdeki pazaryeri store'da mevcutsa
          // bu bölüme kadar olan bilgileri store'da güncelliyoruz
          const index = productMarketplace.findIndex(
            (m) => m.id === marketplace.id
          );
          if (index >= 0) {
            // Store'daki pazaryeri bilgileri
            const pmData = productMarketplace[index];
            this.updateMarketData(pmData, localMarket || data);
          }

          if (localMarket) {
            helper.copy(data, localMarket);
          } else {
            this.marketplaceData.push(data);
          }
        });

        // Store'a son hali gönderiyoruz
        this.product.detail.marketplaces = this.marketplaceData;
      }
    },

    /**
     * Data from API response @param marketplace
     * to update local & store (itself) data
     * Store'daki pazaryeri datasını target keyine eşitler
     */
    updateMarketData(marketplace, target) {
      // Set Active
      target.active = marketplace.is_active || 0;

      // Set Additional Data
      if (marketplace.additional) {
        // Set Category Code
        target.category_code = marketplace.additional.category;

        // Trendyol & Pazarama brand
        if (
          marketplace.additional.brand_id &&
          marketplace.additional.brand_name
        ) {
          const brandData = {
            id: marketplace.additional.brand_id,
            name: marketplace.additional.brand_name,
          };

          // Set Brand
          target.brand = brandData;
        }
        if (
          marketplace.additional.delivery_type &&
          marketplace.additional.delivery_type.length > 0
        ) {
          const delivertTypeData = helper
            .deliveryTypeOption()
            .filter(
              (A) =>
                A.id ==
                (typeof marketplace.additional.delivery_type === "object"
                  ? marketplace.additional.delivery_type[0].id
                  : marketplace.additional.delivery_type)
            );
          if (delivertTypeData) {
            target.delivery_type = delivertTypeData;
          }
        }

        if (
          marketplace.additional.delivery_message &&
          marketplace.additional.delivery_message.length > 0
        ) {
          const delivertMessageTypeData = helper
            .deliveryMessageOption()
            .filter(
              (A) =>
                A.id ==
                (typeof marketplace.additional.delivery_message === "object"
                  ? marketplace.additional.delivery_message[0].id
                  : marketplace.additional.delivery_message)
            );
          if (delivertMessageTypeData) {
            target.delivery_message = delivertMessageTypeData;
          }
        }
        if (
          marketplace.additional.cargoCompany_id &&
          marketplace.additional.cargoCompany_name
        ) {
          target.cargoCompany = {
            id: marketplace.additional.cargoCompany_id,
            name:  marketplace.additional.cargoCompany_name
          }
        }

         if (
        marketplace.additional.alternativeCargoCompany_id &&
          marketplace.additional.alternativeCargoCompany_name
        ) {
          target.alternativeCargoCompany = {
            id: marketplace.additional.alternativeCargoCompany_id,
            name:  marketplace.additional.alternativeCargoCompany_name
          }
        }
        // N11 Custom Data
        if (target.market.name == "n11") {
          if (marketplace.additional.shipmentTemplate) {
            target.shipment_template = marketplace.additional.shipmentTemplate;
          }
          if (marketplace.additional.n11CatalogId) {
            target.catalog_id = marketplace.additional.n11CatalogId;
          }
          if (marketplace.additional.n11ProductId) {
            target.n11product_id = marketplace.additional.n11ProductId;
          }
          if (marketplace.additional.deliveryDay) {
            target.n11_deliveryday = marketplace.additional.deliveryDay;
          }
        }

        // HB Custom Data
        if (target.market.name == "hepsiburada") {
          target.sku_no = marketplace.additional.hepsiburadaSku;
          if (marketplace.additional.deliveryDay) {
            target.hepsiburada_deliveryday = marketplace.additional.deliveryDay;
          }
        }

        if (target.market.name == "vodafone") {
          if (marketplace.additional.deliveryDay) {
            target.vodafone_deliveryday = marketplace.additional.deliveryDay;
          }
        }
      }

      // Change Price Keys
      target.sale_price = marketplace.sale_price || null;
      target.listing_price = marketplace.list_price || null;

      // Set Quantity
      target.fixed_quantity = marketplace.fixed_quantity ? true : false;
      target.quantity = marketplace.quantity;
      target.fixed_price = marketplace.fixed_price ? true : false;

      // Set Category Attributes
      if (
        marketplace.category_attributes &&
        marketplace.category_attributes.length
      ) {
        target.category_attributes = marketplace.category_attributes.sort(
          (a, b) => b.is_require - a.is_require
        );
      }

      if (marketplace.sale_price == null && marketplace.list_price == null) {
        // Set Listing Percent
        if (marketplace.listing_percent) {
          target.listing_percent =
            marketplace.listing_percent_type !== "equal"
              ? marketplace.listing_percent
              : null;
        }

        // Set Sale Percent
        if (marketplace.sale_percent) {
          target.sale_percent =
            marketplace.sale_percent_type !== "equal"
              ? marketplace.sale_percent
              : null;
        }

        // Set Listing Percent Type
        if (marketplace.hasOwnProperty("listing_percent_type")) {
          target.listing_percent_type =
            marketplace.listing_percent_type || null;
        }

        // Set Listing Price Type
        if (marketplace.hasOwnProperty("listing_price_type")) {
          target.listing_price_type = marketplace.listing_price_type || null;
        }

        // Set Percent Type
        if (marketplace.hasOwnProperty("sale_percent_type")) {
          target.sale_percent_type = marketplace.sale_percent_type || null;
        }

        // Set Price Type
        if (marketplace.hasOwnProperty("sale_price_type")) {
          target.sale_price_type = marketplace.sale_price_type || null;
        }

        target.extra_cost = marketplace.extra_cost || 0;
        target.extra_cost_unmask = marketplace.extra_cost;
      }

      // New Match From Marketplace
      if (marketplace.marketplace_category) {
        target.selectedCategory = marketplace.marketplace_category;
      } else if (marketplace.ecommerce_category) {
        target.category = marketplace.ecommerce_category;
      }
    },
  },
  computed: {
    ...mapState(["product", "marketplace"]),
    categoryWithMarketplaces() {
      return this.product.info.categories.filter(
        (item) => item.marketplaces && item.marketplaces.length
      );
    },
  },
  watch: {
    "product.info.categories": function (newVal) {
      if (newVal && newVal.length) {
        this.setMarketplaceList();
      }
    },
  },
  mounted() {
    this.getList({
      onSuccess: () => {
        this.setMarketplaceList();

        // Değişiklik kontrolü için marketplaceData'nın başlangıç halini alıyoruz
        setTimeout(() => {
          this.product.detail.marketplace_initial = helper.clone(
            this.marketplaceData
          );
        }, 2000);
      },
    });
  },
};
</script>
