<template>
  <div>
    <div class="w-100 border-bottom-2 my-3" v-bind:class="getBorderClass">
      <div class="row">
        <div class="col">
          <h5>
            <span v-bind:class="getTextClass">
              {{ marketplace.market.title }}
            </span>
          </h5>
        </div>
        <div class="col-auto" v-if="!isMarketActive">
          <span class="text-muted font-14 alert-dark rounded px-2 py-1"
            >Pazaryeri Pasif</span
          >
          <a
            v-bind:href="editRoute"
            target="_blank"
            class="px-2 py-1 rounded btn-outline-primary cursor-pointer ml-2"
            ><i class="fas fa-edit"></i
          ></a>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-lg-5 p-0 mb-3 mb-lg-0">
        <div class="px-3">
          <!-- Pazaryerinde Yayınla -->
          <div
            class="
              d-flex
              justify-content-between
              align-items-center
              p-3
              mb-3
              bg-light
              rounded
            "
          >
            <label class="custom-label">Pazaryerinde Yayınla</label>
            <WeSwitch
              v-model="marketplace.active"
              v-bind:show-status="true"
              v-bind:disabled="!isMarketActive"
              v-on:change="changeMarketplaceActive"
            />
          </div>
          <!-- ./Pazaryerinde Yayınla -->

          <!-- Fiyat Oluştur -->
          <div class="position-relative" style="margin: 30px 0">
            <hr />

            <label
              class="custom-label mb-3 bg-white px-3 price-title"
              v-bind:class="{ 'text-muted': !isMarketActive }"
              >Fiyat Oluştur</label
            >
          </div>

          <CreatePrice
            v-model="marketplace"
            v-bind:v="v"
            v-bind:product-info="productInfo"
          />

          <!-- ./Fiyat Oluştur -->
          <div
            class="position-relative text-center"
            style="margin: 30px 0 24px 0"
          >
            <hr />
            <div
              class="position-absolute bg-white px-3"
              style="top: -11.5px; left: 50%; margin-left: -60px"
            >
              <div class="d-flex justify-content-center">
                <WeSwitch
                  label="Sabit Fiyat"
                  label-class="custom-label"
                  v-bind:disabled="!marketplace.market.is_active"
                  v-model="marketplace.fixed_price"
                />
              </div>
            </div>
          </div>
          <!-- Listing Price -->
          <WeInput
            v-bind:label="listingPriceLabel"
            prepend="₺"
            small-size="1"
            v-bind:currency="true"
            v-bind:label-disabled="priceDisabled"
            v-bind:disabled="priceDisabled"
            v-bind:error="v ? v.listing_price.$error : null"
            v-on:unmask="marketplace.listing_price_unmask = $event"
            v-model="marketplace.listing_price"
          />
          <!-- ./Listing Price -->

          <!-- Price -->
          <WeInput
            v-if="marketplace.market.name !== 'hepsiburada'"
            v-bind:label="marketplace.market.title + ' Satış Fiyatı'"
            prepend="₺"
            small-size="1"
            v-bind:currency="true"
            v-bind:label-disabled="priceDisabled"
            v-bind:disabled="priceDisabled"
            v-bind:error="
              (v ? v.sale_price.$error : null) ||
              (!priceDisabled &&
                marketplace.listing_price_unmask &&
                marketplace.sale_price_unmask >
                  marketplace.listing_price_unmask)
            "
            v-on:unmask="marketplace.sale_price_unmask = $event"
            v-model="marketplace.sale_price"
          />
          <!-- ./Price -->

          <div
            class="position-relative text-center"
            style="margin: 30px 0 24px 0"
          >
            <hr />
            <div
              class="position-absolute bg-white px-3"
              style="top: -11.5px; left: 50%; margin-left: -60px"
            >
              <div class="d-flex justify-content-center">
                <WeSwitch
                  label="Sabit Stok"
                  label-class="custom-label"
                  v-bind:disabled="!marketplace.market.is_active"
                  v-model="marketplace.fixed_quantity"
                />
              </div>
            </div>
          </div>

          <!-- Quantity -->
          <WeInput
            class="mb-0"
            small-size="1"
            label="Stok Miktarı"
            v-bind:required="marketplace.fixed_quantity"
            v-bind:error="v ? v.quantity.$error : false"
            v-bind:filter-number="true"
            v-bind:label-disabled="
              !isMarketActive || !marketplace.fixed_quantity
            "
            v-bind:disabled="!isMarketActive || !marketplace.fixed_quantity"
            v-model="marketplace.quantity"
          />
          <!-- ./Quantity -->
        </div>
      </div>
      <div class="col">
        <!-- Trendyol && Pazarama && Vodafone -->
        <template
          v-if="
            marketplace.market.name == 'trendyol' ||
            marketplace.market.name == 'vodafone' ||
            marketplace.market.name == 'pazarama'
          "
        >
          <div class="row">
            <div class="col-12 col-md-6">
              <div class="mb-3">
                <h6>
                  <span v-bind:class="getTextClass"> Marka </span>
                </h6>
                <div class="col-12 col-lg-12 pl-0 pr-0">
                  <v-select
                    class="custom-v-select-sm"
                    style="min-width: 192px"
                    v-bind:class="{
                      'v-select--error': v ? v.brand.$error : false,
                    }"
                    label="name"
                    v-bind:options="marketplace.brand_options"
                    v-bind:disabled="!isMarketActive"
                    v-model="marketplace.brand"
                    @search="onSearch"
                  >
                    <template slot="no-options"> Arama yapın </template>
                  </v-select>
                </div>
              </div>
            </div>

            <div
              class="col-12 col-md-6"
              v-if="marketplace.market.name == 'vodafone'"
            >
              <div class="mb-3">
                <h6>
                  <span v-bind:class="getTextClass"> Kargo Firması </span>
                </h6>
                <div class="col-12 col-lg-12 pl-0 pr-0">
                  <v-select
                    label="name"
                    v-bind:options="marketplace.cargoCompany_options"
                    v-bind:reduce="(opt) => opt.id"
                    v-bind:class="{
                      'v-select--error': v ? v.cargoCompany.$error : false,
                    }"
                    v-bind:clearable="false"
                    class="custom-v-select-sm"
                    v-on:input="onSelectCargoCompany"
                    v-bind:value="marketplace.cargoCompany"
                  >
                  </v-select>
                </div>
              </div>
            </div>
            <!-- Vodafone -->
            <div
              class="col-12 col-md-6"
              v-if="marketplace.market.name == 'vodafone'"
            >
              <div class="mb-3">
                <h6>
                  <span v-bind:class="getTextClass"> Teslimat Süresi</span>
                </h6>
                <div class="col-12 col-lg-12 pl-0 pr-0">
                  <WeInput
                    v-model="marketplace.vodafone_deliveryday"
                    v-bind:small-size="true"
                    v-bind:disabled="!isMarketActive"
                    v-bind:filterNumber="true"
                  />
                </div>
              </div>
            </div>

             <div
              class="col-12 col-md-6"
              v-if="marketplace.market.name == 'vodafone'"
            >
              <div class="mb-3">
                <h6>
                  <span v-bind:class="getTextClass"> Alternatif Kargo Firması </span>
                </h6>
                <div class="col-12 col-lg-12 pl-0 pr-0">
                  <v-select
                    label="name"
                    v-bind:options="marketplace.cargoCompany_options"
                    v-bind:reduce="(opt) => opt.id"
                    v-bind:clearable="false"
                    class="custom-v-select-sm"
                    v-on:input="onSelectAlternativeCargoCompany"
                    v-bind:value="marketplace.alternativeCargoCompany"
                  >
                  </v-select>
                </div>
              </div>
            </div>
          </div>

          <!-- ./Vodafone -->
        </template>
        <!-- ./Trendyol && Pazarama -->

        <!-- ÇiçekSepeti -->
        <template v-if="marketplace.market.name == 'ciceksepeti'">
          <div class="mb-3">
            <h6>
              <span v-bind:class="getTextClass"> Teslimat Aralığı </span>
            </h6>
            <div class="col-12 col-lg-6 pl-0">
              <v-select
                v-model="marketplace.delivery_message"
                label="name"
                v-bind:options="deliveryMessageOption"
                v-bind:reduce="(opt) => opt.id"
                v-bind:clearable="false"
                class="custom-v-select-sm"
              >
              </v-select>
            </div>
          </div>
          <div class="mb-3">
            <h6>
              <span v-bind:class="getTextClass"> Teslimat Tipi </span>
            </h6>
            <div class="col-12 col-lg-6 pl-0">
              <v-select
                v-model="marketplace.delivery_type"
                label="name"
                v-bind:options="deliveryTypeOption"
                v-bind:reduce="(opt) => opt.id"
                v-bind:clearable="false"
                class="custom-v-select-sm"
              >
              </v-select>
            </div>
          </div>
        </template>

        <!-- -->

        <!-- N11 -->
        <template v-if="marketplace.market.name == 'n11'">
          <div class="row">
            <div class="col-12 col-md-6">
              <div class="mb-3">
                <div class="row">
                  <div class="col-9">
                    <h6>
                      <span v-bind:class="getTextClass">
                        Teslimat Şablonu
                      </span>
                    </h6>
                  </div>
                  <div class="col-3 mb-2" style="text-align: right">
                    <span
                      class="btn btn-primary btn-sm"
                      v-bind:disabled="!isMarketActive"
                      v-if="!submitShipmentTemplateStatus"
                      v-on:click="getShipmentTemplate"
                      ><i class="fas fa-sync"></i>
                    </span>
                    <span
                      v-else-if="submitShipmentTemplateStatus"
                      class="btn btn-primary btn-sm"
                    >
                      <i class="fas fa-spinner fa-spin"></i>
                    </span>
                  </div>
                </div>
                <div class="col-12 col-lg-12 pr-0 pl-0">
                  <v-select
                    class="custom-v-select-sm p-0"
                    v-bind:class="{
                      'v-select--error': v ? v.shipment_template.$error : false,
                    }"
                    label="name"
                    v-bind:filterable="false"
                    v-bind:options="marketplace.shipment_templates"
                    v-bind:disabled="!isMarketActive"
                    v-bind:clearable="false"
                    v-model="marketplace.shipment_template"
                  >
                    <template slot="no-options">
                      Gösterilecek Sonuç Yok
                    </template>
                  </v-select>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-6">
              <div class="mb-3">
                <h6>
                  <span v-bind:class="getTextClass"> N11 Ürün ID </span>
                </h6>
                <div class="col-12 col-lg-12 pl-0">
                  <WeInput
                    class="mb-0"
                    v-model="marketplace.n11product_id"
                    v-bind:small-size="true"
                    v-bind:disabled="true"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-md-6">
              <div class="mb-3">
                <h6>
                  <span v-bind:class="getTextClass"> Katalog ID </span>
                </h6>
                <div class="col-12 col-lg-12 pl-0">
                  <WeInput
                    class="mb-0"
                    v-model="marketplace.catalog_id"
                    v-bind:small-size="true"
                    v-bind:disabled="!isMarketActive"
                  />
                </div>
              </div>
            </div>
            <div class="col-12 col-md-6">
              <div class="mb-3">
                <h6>
                  <span v-bind:class="getTextClass"> Teslimat Süresi </span>
                </h6>
                <div class="col-12 col-lg-12 pl-0">
                  <WeInput
                    class="mb-0"
                    v-model="marketplace.n11_deliveryday"
                    v-bind:small-size="true"
                    v-bind:disabled="!isMarketActive"
                    v-bind:filterNumber="true"
                  />
                </div>
              </div>
            </div>
          </div>
        </template>
        <!-- ./N11 -->

        <!-- Hepsiburada -->
        <template v-if="marketplace.market.name == 'hepsiburada'">
          <div class="row">
            <div class="col-12 col-md-6">
              <div class="mb-3">
                <h6>
                  <span v-bind:class="getTextClass">
                    Hepsiburada Stok Kodu</span
                  >
                </h6>
                <div class="col-12 col-lg-12 pl-0">
                  <WeInput
                    v-model="marketplace.sku_no"
                    v-bind:small-size="true"
                    v-bind:disabled="!isMarketActive"
                    v-bind:error="v ? v.sku_no.$error : false"
                  />
                </div>
              </div>
            </div>
            <div class="col-12 col-md-6">
              <div class="mb-3">
                <h6>
                  <span v-bind:class="getTextClass"> Teslimat Süresi</span>
                </h6>
                <div class="col-12 col-lg-12 pl-0">
                  <WeInput
                    v-model="marketplace.hepsiburada_deliveryday"
                    v-bind:small-size="true"
                    v-bind:disabled="!isMarketActive"
                    v-bind:filterNumber="true"
                  />
                </div>
              </div>
            </div>
          </div>
        </template>
        <!-- ./Hepsiburada -->
        <h6>
          <span v-bind:class="getTextClass">
            {{ marketplace.market.title }} Kategori</span
          >
        </h6>
        <div class="row align-items-center mb-3" v-if="matchedCategory">
          <div class="row">
            <div class="col-12 col-lg-6">
              <v-select
                v-bind:class="['custom-v-select-sm']"
                style="min-width: 192px"
                placeholder="Eşleşecek Kategori"
                v-bind:options="matchedCategories"
                label="category_name"
                v-bind:disabled="!isMarketActive"
                v-model="marketplace.category"
              ></v-select>
            </div>
          </div>
          <template
            v-if="
              !showNewMatch && matchedCategory && !marketplace.selectedCategory
            "
          >
            <div class="col-auto p-0">
              <i class="fas fa-equals fa-sm"></i>
            </div>
            <div class="col-auto">
              <small class="text-muted"
                >{{ matchedCategory.marketplace_category }} ({{
                  marketplace.market.title
                }})</small
              >
            </div>
          </template>
        </div>

        <!-- Category Tree -->
        <template v-if="showNewMatch || !matchedCategory">
          <CategoryTree
            v-if="!marketplace.selectedCategory"
            v-bind:marketplace="marketplace"
            v-on:on-selected="marketplace.selectedCategory = $event"
            v-model="marketplace.selectedCategory"
            v-bind:disabled="!isMarketActive"
          />
        </template>
        <div
          v-if="marketplace.selectedCategory"
          v-on:click="onRemoveSelected"
          class="btn btn-light mb-3"
        >
          <i class="fas fa-times mr-2"></i>
          <span>{{ marketplace.selectedCategory.name }}</span>
        </div>
        <!-- <div v-if="v.selectedCategory.$error" class="text-danger mb-3">
          <i class="fas fa-exclamation-triangle mr-2"></i>
          <span>Lütfen Kategori eşleştirmesi yapın.</span>
        </div> -->
        <!-- ./Category Tree -->

        <div
          v-if="
            (!showNewMatch && matchedCategory) || marketplace.selectedCategory
          "
        >
          <h6>
            <span v-bind:class="getTextClass"> Kategori Özellikleri</span>
          </h6>

          <!-- Get Features -->
          <span
            class="btn btn-primary"
            v-bind:class="{ disabled: !marketplace.market.is_active }"
            v-if="!submitStatus"
            v-on:click="getFeatures"
          >
            <i class="fas fa-sync mr-2"></i> {{ submitCategoryText }}
          </span>
          <!-- ./Get Features -->

          <!-- Get Features Loader -->
          <span v-else-if="!checkCategoryAttributes" class="btn btn-primary">
            <i class="fas fa-spinner fa-spin"></i>
          </span>
          <!-- ./Get Features Loader -->

          <!-- Get Features -->
          <span
            class="btn btn-indigo ml-2"
            v-bind:class="{ disabled: !marketplace.market.is_active }"
            v-if="
              !submitStatus &&
              (marketplace.market.name === 'n11' ||
                marketplace.market.name === 'hepsiburada')
            "
            v-on:click="getFeatures($event, 'fetch-attributes')"
          >
            <i class="far fa-arrow-alt-circle-down fa-lg mr-2"></i> Özellikleri
            Çek
          </span>
          <!-- ./Get Features -->

          <!-- Category Attributes -->
          <div class="row mt-3" v-if="checkCategoryAttributes">
            <div
              class="col-12 col-lg-6"
              v-for="(attr, index) in marketplace.category_attributes"
              v-bind:key="attr.code + '-' + index"
            >
              <div class="form-group">
                <WeRowInput
                  v-if="marketplace.market.name == 'n11' && !attr.custom"
                  v-bind:form="
                    !attr.is_custom ? 'unreducedSearchSelect' : 'input'
                  "
                  size="sm"
                  select-prop="code"
                  input-class="col-12 col-lg-9"
                  search-key="query"
                  search-route="search/marketplace-category-attribute-values"
                  v-bind:show-asterix="attr.is_require"
                  v-bind:option-prop="attr.values"
                  v-bind:disabled="!isMarketActive"
                  v-bind:label="attr.name"
                  v-bind:clearable="true"
                  v-bind:search-form="getSearchForm(attr)"
                  v-bind:error="
                    v ? v.category_attributes.$each[index].$error : false
                  "
                  v-model="attr.value"
                />
                <WeRowInput
                  v-else-if="
                    !attr.is_custom && marketplace.market.name != 'n11'
                  "
                  v-bind:form="'unreducedSelect'"
                  size="sm"
                  select-prop="code"
                  input-class="col-12 col-lg-9"
                  v-bind:show-asterix="attr.is_require"
                  v-bind:option-prop="attr.values"
                  v-bind:disabled="!isMarketActive"
                  v-bind:label="attr.name"
                  v-bind:clearable="true"
                  v-bind:error="
                    v ? v.category_attributes.$each[index].$error : false
                  "
                  v-model="attr.value"
                />

                <WeRowInput
                  v-else
                  v-bind:form="'input'"
                  size="sm"
                  select-prop="code"
                  input-class="col-12 col-lg-9"
                  v-bind:show-asterix="attr.is_require"
                  v-bind:disabled="!isMarketActive"
                  v-bind:label="attr.name"
                  v-bind:clearable="true"
                  v-bind:error="
                    v ? v.category_attributes.$each[index].$error : false
                  "
                  v-bind:value="attrValueBind(attr)"
                  v-on:input="onCustomAttrInput($event, attr)"
                />
              </div>
            </div>
          </div>
          <!-- ./Category Attributes -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import CategoryTree from "./components/CategoryTree/Index.vue";
import CreatePrice from "./components/CreatePrice/Index.vue";
import { mapState, mapActions } from "vuex";
export default {
  name: "Item",
  components: {
    CategoryTree,
    CreatePrice,
  },
  data() {
    return {
      showNewMatch: false,
      marketplaceConfig: {
        n11: {
          borderClass: "border-danger",
          textClass: "text-danger",
        },
        trendyol: {
          borderClass: "border-orange",
          textClass: "text-orange",
        },
        gittigidiyor: {
          borderClass: "border-primary",
          textClass: "text-primary",
        },
        ciceksepeti: {
          borderClass: "border-primary",
          textClass: "text-primary",
        },
        vodafone: {
          borderClass: "border-danger",
          textClass: "text-danger",
        },
        hepsiburada: {
          borderClass: "border-orange",
          textClass: "text-orange",
        },
        pazarama: {
          borderClass: "border-pink",
          textClass: "text-pink",
        },
      },
      deliveryMessageOption: helper.deliveryMessageOption(),
      deliveryTypeOption: helper.deliveryTypeOption(),
    };
  },
  props: {
    marketplaces: {
      default: () => [],
    },
    index: {
      default: 0,
    },
    categories: {
      default: () => [],
    },
    submitStatus: {
      default: false,
    },
    submitShipmentTemplateStatus: {
      default: false,
    },
    v: null,
  },
  methods: {
    ...mapActions("marketplace", ["getMarketplaceCargoCompanies"]),
    getConfig() {
      return this.marketplaceConfig[this.marketplace.market.name] || null;
    },
    onSelectCargoCompany(e) {
      const cargoCompany = this.marketplace.cargoCompany_options.find(
        (A) => A.id == e
      );
      if (cargoCompany) {
        this.marketplace.cargoCompany = cargoCompany;
      }
    },
     onSelectAlternativeCargoCompany(e) {
      const cargoCompany = this.marketplace.cargoCompany_options.find(
        (A) => A.id == e
      );
      if (cargoCompany) {
        this.marketplace.alternativeCargoCompany = cargoCompany;
      }
    },
    attrValueBind(attr) {
      return attr?.value?.name ?? attr.value;
    },
    onCustomAttrInput(e, attr) {
      attr.value = { code: e, name: e };
    },
    getFeatures(payload, isFetch = null) {
      if (this.marketplace.market.is_active) {
        this.marketplace.category_attributes = [];
        this.marketplace.isFetch = isFetch;
        this.$emit("get-features", this.marketplace);
      }
    },
    onSearch(search, loading) {
      if (search.length) {
        loading(true);
        this.search(loading, search, this.marketplace, this);
      }
    },
    search: _.debounce((loading, search, marketplace, vm) => {
      const data = {};
      data.marketplace = marketplace;
      data.loading = loading;
      data.query = search;

      vm.$emit("search-brand", data);
    }, 350),
    getAttrValues(attr) {
      if (!attr.values) return [];
      const valuesWithOutParent = attr.values.filter((item) => !item.parent_id);

      const valuesBelongsToParent = attr.values.filter((attrValue) => {
        if (attrValue.parent_id) {
          const parentId = attrValue.parent_id;
          return this.marketplace.category_attributes.find((item) => {
            return (
              item.hasOwnProperty("value") &&
              item.value &&
              item.value.attr_id == parentId
            );
          });
        }
      });

      if (valuesBelongsToParent && valuesBelongsToParent.length) {
        return valuesBelongsToParent;
      }

      return valuesWithOutParent.length ? valuesWithOutParent : attr.values;
    },
    getSearchForm(attr) {
      const data = {};
      data.marketplace_id = this.marketplace.market.id;
      data.code = attr.code;

      if (attr.name == "Model") {
        const parentData = this.marketplace.category_attributes.find((item) => {
          return (
            item.name == "Marka" && item.hasOwnProperty("value") && item.value
          );
        });

        if (parentData) {
          data.parent = parentData.value.attr_id;
        }
      }

      return data;
    },
    onRemoveSelected() {
      this.$swal({
        title: "İşlemi Onaylıyor Musunuz ?",
        text: "Ürünün pazaryeri kategorisi kaldırılacak. Kategori özellikleri silinecek.",
        icon: "warning",
        showCancelButton: "İptal",
        confirmButtonText: "Kaldır",
      }).then((confirm) => {
        if (confirm.isConfirmed) {
          this.marketplace.selectedCategory = null;
          this.showNewMatch = false;
          this.clearCategoryAttributes();
        }
      });
    },
    getShipmentTemplate() {
      if (this.marketplace.market.is_active) {
        this.$emit("get-shipment-templates", this.marketplace);
      }
    },
    clearCategoryAttributes() {
      this.marketplace.category_attributes = [];
    },
    changeMarketplaceActive(checked) {
      if (!checked && this.$route.params.id) {
        this.$swal({
          title: `Bu ürün ${this.marketplace.market.title} pazaryerinde satışa kapatılacaktır !`,
          icon: "warning",
          showCancelButton: false,
        });
      }
    },
  },
  computed: {
    ...mapState({
      productInfo: (state) => state.product.info,
    }),
    marketplace: {
      get() {
        return this.marketplaces[this.index];
      },
      set(value) {
        this.marketplaces[this.index] = value;
      },
    },
    isMarketActive() {
      return this.marketplace.market.is_active;
    },
    editRoute() {
      return this.marketplace
        ? `/marketplaces/detail/${this.marketplace.market.id}`
        : "/";
    },
    getBorderClass() {
      const itemData = this.getConfig();
      if (this.isMarketActive) {
        return itemData ? itemData.borderClass : "border-dark";
      }
      return "border-secondary";
    },
    getTextClass() {
      const itemData = this.getConfig();
      if (this.isMarketActive) {
        return itemData ? itemData.textClass : "text-dark";
      }
      return "text-secondary";
    },
    checkCategoryAttributes() {
      return (
        this.marketplace.category_attributes &&
        this.marketplace.category_attributes.length
      );
    },
    submitCategoryText() {
      return this.checkCategoryAttributes
        ? "Özellikleri Yenile"
        : "Özellikleri Getir";
    },
    matchedCategory() {
      const data = this.categories.find((item) => {
        return item.marketplaces.filter((m) => {
          return m.marketplace_id == this.marketplace.market.id;
        }).length;
      });
      let result = null;

      if (data) {
        const categoryFound = data.marketplaces.find(
          (item) => item.marketplace_id == this.marketplace.market.id
        );

        result = {};
        result.category_name = data.name;
        result.marketplace_category = categoryFound
          ? categoryFound.category_name
          : null;
      }
      return result;
    },
    priceDisabled() {
      return !this.isMarketActive || !this.marketplace.fixed_price;
    },
    matchedCategories() {
      let result = [];
      const newMatch = {
        id: null,
        category_name: "Yeni Kategori Eşleştir",
      };

      const data = this.categories.filter((item) => {
        return item.marketplaces.filter((m) => {
          return m.marketplace_id == this.marketplace.market.id;
        }).length;
      });

      let mappedData = data.map((item) => {
        const getMatchedMarketplace = item.marketplaces.find(
          (market) => market.marketplace_id == this.marketplace.market.id
        );

        const data = {};
        data.id = item.id;
        data.category_name = item.name;
        data.category_code = getMatchedMarketplace
          ? getMatchedMarketplace.category_code
          : null;
        return data;
      }, []);

      const checkNewMatch = mappedData.filter((item) => item.id === null);
      if (!checkNewMatch.length) {
        result = [...mappedData, newMatch];
      } else {
        result = mappedData;
      }

      return result;
    },
    checkMarketplaceSellPrice() {
      return this.marketplace.price_type !== "-1";
    },
    listingPriceLabel() {
      let result = "Piyasa Satış Fiyatı (Üstü Çizili Fiyat)";

      if (this.marketplace.market.name == "hepsiburada") {
        result = "Hepsiburada Satış Fiyatı";
      }

      return result;
    },
  },
  watch: {
    // "marketplace.active": function (status) {
    //   if (status) {
    //     if (this.marketplace.market.name == "n11") {
    //       this.$emit("get-shipment-templates", this.marketplace);
    //     }
    //   }
    // },
    "marketplace.category": function (newCategory) {
      if (newCategory && newCategory.id === null) {
        this.showNewMatch = true;
      } else {
        this.marketplace.selectedCategory = null;
        this.showNewMatch = false;
      }
    },
    deep: true,
  },
  mounted() {
    // if (
    //   this.marketplace.market.name == "n11" &&
    //   this.marketplace.market.is_active &&
    //   !this.marketplace.shipment_templates.length
    // ) {
    //   this.$emit("get-shipment-templates", this.marketplace);
    // }

    if (
      this.marketplace.market.name == "vodafone" &&
      this.marketplace.market.is_active
    ) {
      this.getMarketplaceCargoCompanies({
        marketplace: this.marketplace.market,
        onSuccess: (result) => {
          this.marketplace.cargoCompany_options = result.data;
        },
      });
    }
  },
};
</script>
<style lang="scss">
.price-title {
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 0;
}
</style>