<template>
  <div>
    <!-- Fiyat Oluştur --- Liste Fiyatı -->
    <div class="d-block">
      <div class="d-flex justify-content-between">
        <label
          for="listing_price_type"
          v-bind:class="[
            'custom-label',
            isDisabled || isDeactive ? 'text-muted' : '',
          ]"
          >{{ marketplacePriceLabel }}</label
        >
        <span
          v-bind:class="[
            'font-14',
            marketplace.fixed_price || isDeactive ? 'line-through' : '',
            isDisabled || isDeactive ? 'text-muted' : '',
          ]"
          ><b
            v-bind:class="{
              'text-danger':
                marketplace.market.name != 'hepsiburada' &&
                !isDeactive &&
                marketplaceListingPrice() < marketplaceSalePrice(),
            }"
            >{{ marketplaceListingPrice() | moneyFormat }}</b
          >
          <i
            v-if="
              marketplace.market.name != 'hepsiburada' &&
              !isDeactive &&
              marketplaceListingPrice() < marketplaceSalePrice()
            "
            v-tooltip.bottom="'Liste Fiyatı, Satış Fiyatından küçük olamaz.'"
            class="fas fa-exclamation-triangle text-danger ml-1"
          ></i
        ></span>
      </div>
      <div class="row align-items-center mb-3">
        <!-- Base Price -->
        <div class="col">
          <select
            id="listing_price_type"
            class="custom-select custom-select-sm"
            v-bind:disabled="isDisabled || isDeactive"
            v-model="marketplace.listing_price_type"
          >
            <option
              v-for="priceOption in priceOptions"
              v-bind:key="priceOption.id"
              v-bind:value="priceOption.id"
            >
              {{ priceOption.name }}
            </option>
          </select>
        </div>
        <!-- ./Base Price -->

        <!-- Listing Percent Type -->
        <div class="col pl-0">
          <WeInput
            class="mb-0"
            v-bind:filter-number="true"
            max-value="100"
            v-bind:prepend="
              marketplace.listing_percent_type !== 'equal' &&
              marketplace.listing_percent_type !== 'deactive'
                ? '%'
                : ''
            "
            small-size="1"
            autocomplete="off"
            v-bind:error="v ? v.listing_percent.$error : false"
            v-bind:disabled="listingPercentDisabled"
            v-bind:input="
              marketplace.listing_percent_type == 'equal'
                ? null
                : marketplace.listing_percent
            "
            v-on:input="marketplace.listing_percent = $event"
          >
            <template v-slot:append>
              <select
                class="custom-select custom-select-sm border-left-radius-0"
                v-bind:disabled="isDisabled"
                v-model="marketplace.listing_percent_type"
              >
                <option
                  v-for="percentOption in getPercentOptions"
                  v-bind:key="percentOption.id"
                  v-bind:value="percentOption.id"
                >
                  {{ percentOption.name }}
                </option>
              </select>
            </template>
          </WeInput>
        </div>
        <!-- ./Listing Percent Type -->
      </div>
    </div>
    <!-- Fiyat Oluştur --- Liste Fiyatı -->

    <!-- Fiyat Oluştur --- Satış Fiyatı -->
    <div class="d-block" v-if="market.name !== 'hepsiburada'">
      <div class="d-flex justify-content-between">
        <label
          for="listing_price_type"
          v-bind:class="['custom-label', isDisabled ? 'text-muted' : '']"
          >{{ market.title }} Satış Fiyatı</label
        >
        <span
          v-bind:class="[
            'font-14',
            marketplace.fixed_price ? 'line-through' : '',
            isDisabled ? 'text-muted' : '',
          ]"
        >
          <b>{{ marketplaceSalePrice() | moneyFormat }}</b></span
        >
      </div>
      <div class="row align-items-center">
        <!-- Base Price -->
        <div class="col">
          <select
            class="custom-select custom-select-sm"
            v-bind:disabled="isDisabled"
            v-model="marketplace.sale_price_type"
          >
            <option
              v-for="priceOption in priceOptions"
              v-bind:key="priceOption.id"
              v-bind:value="priceOption.id"
            >
              {{ priceOption.name }}
            </option>
          </select>
        </div>
        <!-- ./Base Price -->

        <!-- Percent Type -->
        <div class="col pl-0">
          <WeInput
            class="mb-0"
            v-bind:filter-number="true"
            max-value="100"
            v-bind:prepend="
              marketplace.sale_percent_type !== 'equal' ? '%' : ''
            "
            small-size="1"
            autocomplete="off"
            v-bind:error="v ? v.sale_percent.$error : false"
            v-bind:disabled="salePercentDisabled"
            v-bind:input="
              marketplace.sale_percent_type == 'equal'
                ? null
                : marketplace.sale_percent
            "
            v-on:input="marketplace.sale_percent = $event"
          >
            <template v-slot:append>
              <select
                class="custom-select custom-select-sm border-left-radius-0"
                style="min-width: 96.4px"
                v-bind:disabled="isDisabled"
                v-model="marketplace.sale_percent_type"
              >
                <option
                  v-for="percentOption in percentOptions"
                  v-bind:key="percentOption.id"
                  v-bind:value="percentOption.id"
                >
                  {{ percentOption.name }}
                </option>
              </select>
            </template>
          </WeInput>
          <!-- ./Percent Type -->
        </div>
      </div>
    </div>
    <!-- Fiyat Oluştur --- Satış Fiyatı -->

    <div class="d-block mt-3">
      <WeInput
        label="İlave Maliyet"
        prepend="₺"
        small-size="1"
        v-bind:disabled="isDisabled"
        v-bind:currency="true"
        v-on:unmask="marketplace.extra_cost_unmask = $event"
        v-model="marketplace.extra_cost"
      />
    </div>
  </div>
</template>
<script>
export default {
  name: "CreatePrice",
  data() {
    return {
      percentOptions: [
        {
          id: "equal",
          name: "Eşitle",
        },
        {
          id: "increase",
          name: "Artır",
        },
        {
          id: "decrease",
          name: "Azalt",
        },
      ],
      priceOptions: [
        {
          id: "price",
          name: "Ürün Satış Fiyatını",
        },
        {
          id: "discounted_price",
          name: "Ürün İndirimli Fiyatını",
        },
      ],
    };
  },
  props: {
    marketplace: {
      default: null,
    },
    v: {
      default: null,
    },
    productInfo: {
      default: null,
    },
  },
  model: {
    prop: "marketplace",
  },
  methods: {
    /**
     * Yüzde Artır/Azalt hesaplama methodu
     */
    priceCalculator(price, percent, percentType) {
      const extraCost = this.marketplace.extra_cost_unmask || 0;

      let multiplier = 0;
      if (percentType == "increase") multiplier = 1;
      if (percentType == "decrease") multiplier = -1;

      let result = price + ((price * percent) / 100) * multiplier;
      if (result > 0) result += extraCost;

      return result;
    },
    /**
     * Pazaryerine yansıyacak olan liste fiyatı
     */
    marketplaceListingPrice() {
      const percent = this.marketplace.listing_percent || 0;
      const priceType = this.marketplace.listing_price_type;
      const percentType = this.marketplace.listing_percent_type;
      const price =
        priceType == "price"
          ? this.productInfo.normalPrice.unmask
          : this.productInfo.discountedPrice.unmask;

      return this.priceCalculator(price, percent, percentType);
    },
    /**
     * Pazaryeri satış fiyatı
     */
    marketplaceSalePrice() {
      const percent = this.marketplace.sale_percent || 0;
      const priceType = this.marketplace.sale_price_type;
      const percentType = this.marketplace.sale_percent_type;
      const price =
        priceType == "price"
          ? this.productInfo.normalPrice.unmask
          : this.productInfo.discountedPrice.unmask;

      return this.priceCalculator(price, percent, percentType);
    },
  },
  computed: {
    market() {
      return this.marketplace.market;
    },

    marketplacePriceLabel() {
      let result = "Piyasa Satış Fiyatı (Üstü Çizili Fiyat)";
      if (this.marketplace.market.name == "hepsiburada") {
        result = this.marketplace.market.title + " Satış Fiyatı";
      }
      return result;
    },

    /**
     * Liste Fiyatı Aktif/Pasif
     */
    listingPercentDisabled() {
      return (
        this.isDisabled ||
        this.marketplace.listing_percent_type == "equal" ||
        this.isDeactive
      );
    },
    /**
     * Satış Fiyatı Aktif/Pasif
     */
    salePercentDisabled() {
      return this.isDisabled || this.marketplace.sale_percent_type == "equal";
    },
    /**
     * Pazaryerinin ayarlardaki aktif durum kontrolü ve sabit fiyatın aktif olup olmadığı kontrolü
     */
    isDisabled() {
      return !this.market.is_active || this.marketplace.fixed_price;
    },
    isDeactive() {
      return this.marketplace.listing_percent_type == "deactive";
    },
    /**
     * Artır, Azalt, Güncelle kısmına ek olarak Kullanma Seçeneği
     */
    getPercentOptions() {
      const selection = {
        id: "deactive",
        name: "Kullanma",
      };

      if (this.marketplace.market.name == "hepsiburada") {
        return this.percentOptions;
      }

      return [...this.percentOptions, selection];
    },
  },
};
</script>
<style lang="scss">
.line-through {
  text-decoration: line-through;
}
</style>