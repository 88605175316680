<template>
  <WeCard>
    <div class="row">
      <div class="col">
        <h5 class="custom-card-title">
          <i class="fas fa-cart-plus"></i>
          <span>PAZARYERİ</span>
        </h5>
      </div>
      <div
        class="col-auto"
        v-if="productDetail && productDetail.stockCode && productDetail.name"
      >
        <span class="text-muted"
          >{{ productDetail.stockCode }} / {{ productDetail.name }}</span
        >
      </div>
    </div>
    <hr />
    <div class="row align-items-start">
      <!-- Marketplace Tab Header -->
      <div class="col-12 col-xl-auto">
        <ul
          class="nav nav-pills p-0 custom-scrollbar"
          id="pills-tab"
          role="tablist"
          style="
            white-space: nowrap;
            max-width: 100%;
            overflow: auto;
            display: block;
          "
        >
          <li
            class="nav-item d-inline-block mr-2 d-xl-block"
            role="presentation"
            v-for="(mp, index) in getMarketplaces"
            v-bind:key="mp.market.name"
          >
            <a
              class="
                d-flex
                align-items-center
                btn btn-light
                px-5
                justify-content-center
                w-100
              "
              style="min-height: 80px"
              data-toggle="pill"
              role="tab"
              aria-selected="true"
              v-bind:id="mp.market.name + '-tab'"
              v-bind:class="{
                'mb-xl-2': index !== getMarketplaces.length - 1,
                'ml-2 ml-xl-0': index !== 0,
                'glow-it--danger': $v.getMarketplaces.$each[index].$error,
              }"
              v-bind:href="'#' + mp.market.name + '-link'"
              v-bind:aria-controls="mp.market.name + '-link'"
            >
              <div class="p-3">
                <img
                  v-if="mp.market.logo"
                  v-bind:src="mp.market.logo"
                  width="80"
                />
                <span v-else class="font-weight-bold">
                  {{ mp.market.title }}
                </span>
              </div>
            </a>
          </li>
        </ul>
      </div>
      <!-- ./Marketplace Tab Header -->
      <div class="col-12 col-xl">
        <!-- Marketplace Tab Content -->
        <div class="tab-content" id="pills-tabContent">
          <div
            v-for="(item, index) in getMarketplaces"
            v-bind:key="item.market.name"
            v-bind:class="{ 'show active': index === 0 }"
            class="tab-pane fade"
            v-bind:id="item.market.name + '-link'"
            role="tabpanel"
            v-bind:aria-labelledby="item.market.name + '-tab'"
          >
            <WeCard
              v-if="
                item.market.name == 'n11' &&
                $route.params.id &&
                item.market.is_active
              "
              class="alert-info mb-4 col-12 col-xl-6"
            >
              <i class="fas fa-info-circle mr-2"></i> Bu ürünün güncel durumu,
              N11 Pazaryerine <b>1 Dakika</b> sonra yansıyacaktır.
            </WeCard>
            <Item
              v-bind:marketplaces="getMarketplaces"
              v-bind:index="index"
              v-bind:categories="categories"
              v-on:get-features="getFeatures"
              v-on:search-brand="getBrand"
              v-bind:submitShipmentTemplateStatus="submitShipmentTemplateStatus"
              v-on:get-shipment-templates="getShipmentTemplateData"
              v-bind:v="$v.getMarketplaces.$each[index]"
              v-bind:submit-status="featureSubmit"
            />
          </div>
        </div>
        <!-- ./Marketplace Tab Content -->
      </div>
    </div>
  </WeCard>
  <!-- ./Marketplace Accordion Body -->
</template>
<script>
import Item from "./views/Item/Index";
import { mapActions } from "vuex";
import { requiredIf } from "vuelidate/lib/validators";
export default {
  name: "Content",
  data() {
    return {
      localData: this.data,
      featureSubmit: false,
      submitShipmentTemplateStatus: false
    };
  },
  validations: {
    getMarketplaces: {
      $each: {
        /**
         * İndirimli Fiyat Doğrulaması
         * -- Market aktif ise, pazaryerinde yayınla aktif ise ve Sabit Stok işaretlendiyse
         * -- sale_price alanı zorunlu
         */
        sale_price: {
          required: requiredIf(function (parent) {
            return (
              parent.market.name !== "hepsiburada" &&
              parent.market.is_active &&
              parent.active &&
              parent.fixed_price &&
              !parent.sale_price_unmask
            );
          }),
          maxValue: function (value, parent) {
            let result = true;

            if (
              parent.market.name !== "hepsiburada" &&
              parent.market.is_active &&
              parent.active &&
              parent.fixed_price
            ) {
              const listingPrice = parent.listing_price_unmask;
              const currentValue = parent.sale_price_unmask;

              result = listingPrice >= currentValue;
            }

            return result;
          },
        },
        /**
         * Satış Fiyatı Doğrulaması
         * -- Market aktif ise, pazaryerinde yayınla aktif ise ve Sabit Stok işaretlendiyse
         * -- listing_price alanı zorunlu
         */
        listing_price: {
          required: requiredIf(function (parent) {
            return (
              parent.market.is_active &&
              parent.active &&
              parent.fixed_price &&
              !parent.listing_price_unmask
            );
          }),
        },
        /**
         * Kategori Eşleştirme Doğrulaması
         * -- Market aktif ise, pazaryerinde yayınla aktif ise, Kategorinin üst kategorisi mevcut değil ise ve id'si yok ise
         * -- selectedCategory alanı zorunlu
         */
        // selectedCategory: {
        //   required: requiredIf(function (parent) {
        //     return (
        //       parent.active &&
        //       parent.market.is_active &&
        //       (!parent.category || parent.category.id == null) &&
        //       parent?.market?.name !== "hepsiburada"
        //     );
        //   }),
        // },
        /**
         * Marka Doğrulaması
         * -- Market aktif ise, pazaryerinde yayınla aktif ise, pazaryeri name'i trendyol ise ve marka belirtilmediyse
         * -- brand alanı zorunlu
         */
        brand: {
          required: requiredIf(function (parent) {
            return (
              parent.active &&
              parent.market.is_active &&
              (parent.market.name === "trendyol" ||
                parent.market.name === "vodafone") &&
              !parent.brand
            );
          }),
        },

        cargoCompany: {
          required: requiredIf(function (parent) {
            return (
              parent.active &&
              parent.market.is_active &&
              parent.market.name === "vodafone" &&
              !parent.cargoCompany
            );
          }),
        },

        delivery_message: {
          required: requiredIf(function (parent) {
            return (
              parent.active &&
              parent.market.is_active &&
              parent.market.name === "ciceksepeti" &&
              !parent.delivery_message
            );
          }),
        },

        delivery_type: {
          required: requiredIf(function (parent) {
            return (
              parent.active &&
              parent.market.is_active &&
              parent.market.name === "ciceksepeti" &&
              !parent.delivery_type
            );
          }),
        },
        /**
         * Teslimat Şablonu Doğrulaması
         * -- Market aktif ise, pazaryerinde yayınla aktif ise, pazaryeri name'i n11 ise ve teslimat şablonu seçilmediyse
         * -- shipment_template alanı zorunlu
         */
        shipment_template: {
          required: requiredIf(function (parent) {
            return (
              parent.market.is_active &&
              parent.active &&
              !this.$route.params.id &&
              parent.market.name === "n11" &&
              !parent.shipment_template
            );
          }),
        },
        /**
         * Kategori Özellikleri Doğrulaması
         * -- Pazaryerinde yayınla aktif ise, özellğin is_require key'i true ise
         * -- category_attributes[$index] alanı zorunlu
         */
        category_attributes: {
          $each: {
            value: {
              required: requiredIf(function (parent) {
                // Parent array
                return (
                  parent?.market?.is_active &&
                  parent.is_require &&
                  parent?.market?.name !== "hepsiburada"
                );
              }),
            },
          },
        },
        /**
         * Satış Fiyat Yüzde Doğrulaması
         * -- Market aktif ise, pazaryerinde yayınla aktif ise, Sabit Fiyat aktif ise ve Satış Fiyat Yüzdesi "Eşitle" değil ise
         * -- listing_percent alanı zorunlu
         */
        listing_percent: {
          required: requiredIf(function (parent) {
            return (
              parent.market.is_active &&
              parent.active &&
              !parent.fixed_price &&
              parent.listing_percent_type !== "equal" &&
              parent.listing_percent_type !== "deactive"
            );
          }),
        },
        /**
         * İndirimli Fiyat Yüzde Doğrulaması
         * -- Market aktif ise, pazaryerinde yayınla aktif ise, Sabit Fiyat aktif ise ve İndirimli Fiyat Yüzdesi "Eşitle" değil ise
         * -- sale_percent alanı zorunlu
         */
        sale_percent: {
          required: requiredIf(function (parent) {
            return (
              parent.market.is_active &&
              parent.active &&
              !parent.fixed_price &&
              parent.sale_percent_type !== "equal"
            );
          }),
        },
        /**
         * Stok Miktar Doğrulaması
         * -- Market aktif ise, pazaryerinde yayınla aktif ise ve Sabit Fiyat aktif ise
         * -- quantity alanı zorunlu
         */
        quantity: {
          required: requiredIf(function (parent) {
            return (
              parent.market.is_active && parent.active && parent.fixed_quantity
            );
          }),
        },
        /**
         * Stok Kodu Doğrulaması
         * -- Market aktif ise, pazaryerinde yayınla aktif ise, pazaryeri name'i hepsiburada ise ve sku_no mevcut değil ise
         * -- sku_no alanı zorunlu
         */
        sku_no: {
          required: requiredIf(function (parent) {
            return (
              parent.market.is_active &&
              parent.active &&
              parent.market.name === "hepsiburada" &&
              !parent.sku_no
            );
          }),
        },
      },
    },
  },
  props: {
    marketplaces: {
      default: () => [],
    },
    data: {
      default: null,
    },
    categories: {
      default: () => [],
    },
    productDetail: {
      default: null,
    },
  },
  model: {
    prop: "data",
  },
  components: {
    Item,
  },
  methods: {
    ...mapActions("marketplace", [
      "getMarketplaceCategoryFeatures",
      "searchBrand",
      "getShipmentTemplates",
    ]),

    getFeatures(item) {
      this.featureSubmit = true;
      if (item.selectedCategory || item.category) {
        let code;

        if (item.selectedCategory) {
          code = item.selectedCategory.id;
        } else if (item.category) {
          code = item.category.category_code;
        }
        this.getMarketplaceCategoryFeatures({
          marketplace: {
            ...item.market,
            category_code: code,
            isFetch: item.isFetch,
          },
          onSuccess: (result) => {
            if (item.isFetch) {
              this.$swal({
                title: "Başarılı",
                text: "Kategori özellikleri çekme işlemi alındı. Kısa süre sonra tamamlanacaktır.",
                icon: "success",
              });
            } else {
              if (result.data && result.data.items) {
                let resultData = result.data.items;
                let attributes = [];

                if (resultData && resultData.length) {
                  attributes = resultData
                    .map((item) => {
                      if (item.values && typeof item.values == "object") {
                        item.values = Object.keys(item.values).map((key) => {
                          return item.values[key];
                        }, []);
                      }
                      return item;
                    })
                    .sort((a, b) => b.is_require - a.is_require);
                }

                item.category_attributes = attributes;
              }
            }
          },
          onFinish: () => {
            this.featureSubmit = false;
          },
        });
      } else {
        this.featureSubmit = false;
      }
    },
    getBrand(data) {
      this.searchBrand({
        form: data,
        onSuccess: (result) => {
          const resultData = result.data ? result.data.data : null;
          if (resultData) {
            data.marketplace.brand_options = JSON.parse(resultData);
          }
          data.loading(false);
        },
      });
    },
    getShipmentTemplateData(data) {
      this.submitShipmentTemplateStatus = !this.submitShipmentTemplateStatus
      this.getShipmentTemplates({
        onSuccess: (response) => {
          if (
            response.data &&
            response.data.status &&
            response.data.status !== "failure"
          ) {
            data.shipment_templates = JSON.parse(response.data.data);
          } else {
            console.warn(`N11 Hatası: (${response.data.error["n11.0"]})`);
          }
          this.submitShipmentTemplateStatus = !this.submitShipmentTemplateStatus
        },
      });
    },
  },
  computed: {
    getMarketplaces: {
      get() {
        return this.marketplaces && this.marketplaces.length
          ? this.marketplaces
              .filter((item) => {
                if (item.hasOwnProperty("category_attributes")) {
                  item.category_attributes.map((ca) => {
                    ca.market = item.market;
                    return ca;
                  });
                }
                return item;
              })
              .sort((a, b) => b.market.is_active - a.market.is_active)
          : [];
      },
      set(value) {
        this.computedData = value;
      },
    },
    computedData: {
      get() {
        return this.data;
      },
      set(value) {
        this.localData = value;
      },
    },
  },
  watch: {
    marketplaces: function (newVal) {
      this.computedData = newVal;
    },
  },
};
</script>
